// src/templates/posts/Report.js

import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import PageTitle from '../../components/PageTitle'
import ResourceMetadata from '../../components/ResourceMetadata'
import ResourceHero from '../../components/ResourceHero.js'
import ResourceCards from '../../components/ResourceCards.js'
import RichText from '../../layouts/RichText.js'
import AccessReportButton from '../../components/Buttons/AccessReportButton.js'
import DownloadButton from '../../components/Buttons/DownloadButton.js'

function Report({ data }) {
  const postData = data.wpReport
  const hubSpotUrl = postData.report.hubspotUrl
  const reportPdf = postData.report.reportPdf
  const downloadTitle =
    reportPdf &&
    reportPdf.title + '-' + reportPdf &&
    reportPdf.localFile.prettySize
  const downloadLink = reportPdf && reportPdf.localFile.publicURL

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let postTags = []

      postData.report.relatedTopics.map((node, index) =>
        postTags.push(node.name)
      )

      const gtmArgs = {
        pageType: 'report',
        publishDate: postData.date,
        tags: postTags,
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(gtmArgs)
    }
  }, [postData])

  return (
    <Layout>
      <Seo post={postData} />

      <article className="space-y-responsive-xl-48">
        <section className="report-title text-center">
          <PageTitle
            label="Report"
            title={postData.title}
            url={postData.uri}
            download={downloadLink}
            downloadTitle={downloadTitle}
            hubSpotUrl={hubSpotUrl}
          />

          <div className="container">
            <ResourceHero heroImage={postData.report.heroImage} />
          </div>

          <ResourceMetadata
            author={postData.report.author}
            relatedTopics={postData.report.relatedTopics}
            date={postData.date}
          />
        </section>

        <article>
          <RichText text={postData.report.mainContent} />
        </article>

        {!hubSpotUrl && downloadLink && (
          <div className="b flex justify-center">
            <DownloadButton url={downloadLink} title={downloadTitle} />
          </div>
        )}

        {hubSpotUrl && (
          <div className="b flex justify-center">
            <AccessReportButton url={hubSpotUrl} />
          </div>
        )}

        <section className="related-resources">
          <div className="container">
            <h2 className="mb-responsive-xl-32 border-b border-granite-200 pb-responsive-xl-8 text-center text-sm font-medium uppercase tracking-wide">
              Related Resources
            </h2>
            <ResourceCards
              manualResources={postData.report.relatedResources}
              automatedResources={data.allWpReport}
            />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default Report

export const query = graphql`
  query ($id: String!) {
    wpReport(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      uri
      date(formatString: "M/D/YYYY")
      report {
        mainContent
        hubspotUrl
        reportPdf {
          title
          localFile {
            publicURL
            prettySize
          }
        }
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 1380
                width: 2100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        relatedTopics {
          uri
          name
        }
        relatedResources {
          ... on WpArticle {
            ...ArticleResourceFragment
          }
          ... on WpPodcast {
            ...PodcastResourceFragment
          }
          ... on WpReport {
            ...ReportResourceFragment
          }
          ... on WpWebinar {
            ...WebinarResourceFragment
          }
        }
      }
    }
    allWpReport(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...ReportResourceFragment
        }
      }
    }
  }
`
